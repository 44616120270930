#wrapper.pt_checkout {
    #main #primary {
        .checkout-shipping {
			.form-caption {
				margin: 0.4375rem 0 0;
				@include respond(small) {
					margin: 0;
				}
			}
			.form-row {
				&.label-inline.form-indent {
					@include respond(small) {
						margin: 0;
					}
				}
				&.label-inline.form-indent {
					input[type=checkbox] + label,
					input[type=radio] + label {
						top: 0;
						width: auto
					}
				}

				.form-caption {
					margin: 0.4375rem 0 0 0;
					@include respond(small) {
						margin: 0;
					}
				}
				.field-wrapper {
					width: 74%;
					min-height: 34px;
					@include respond(small) {
						width: 100%;
					}
				}
				.input-select.country {
					& + .selected-country {
						min-height: 34px;
    					line-height: 34px;
					}
				}
			}

            &.form-horizontal {
                .form-row {
                    .form-caption {
                        padding-left: 0;
                    }
                }
            }
            .form-field-tooltip {
				left: auto;
				right: 0;
				width: auto;
                @include respond(small) {
                    position: absolute;
                    top: 8px;
                }
            }
            .form-row.required {
                label {
                    span {
                        &:not(.required-indicator) {
                            @include respond(large) {
                                display: block;
                                height: 32px;
                            }
                        }
                    }
                }
            }
            .useAsBillingAddress + label {
                margin-top: 16px;
            }
			.credit-check-policy {
				padding-top: 10px;
    			border-top: 1px solid #e8e8e8;
				display: none;
				&.show {
					display: block;
				}
			}
            h2.h2-flex {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                .address-tlt {
                    flex: 0;
                    white-space: nowrap;
                    @include respond(extra-small) {
                        flex-basis: 100%;
                    }
                }
                .address-pers {
                    font-weight: 300;
                    padding: 4px 0 0 4px;
                    font-size: rem(11px);
                    line-height: 12px;
                    flex: 1;
                }
            }
        }
        .checkout-progress-indicator {
            > div {
                padding: 0 8px;
            }
            &.multiship {
                > div {
                    a, .name {
                        @include respond(small) {
                            font-size: 10px;
                            letter-spacing: -.025rem;
                        }
                        @include respond(iphone) {
                            font-size: 10px;
                            letter-spacing: -.025rem;
                        }
                    }
                }
            }
        }
        .summary {
            .summary-address {
                > div {
                    width: 100%;
                }
            }
        }
    }
}
