/////////////////////////
// Checkout Summary Panel
/////////////////////////
#wrapper.pt_checkout {
	#main {
		#primary {
			.order-summary-footer {
				.checkout-privacy-policy {
					float: left;
					@include font-regular(12px);
					@include line-height(20px);
					text-align: left;
					padding: rem(14px) 0;
					margin-bottom: 24px;
					a {
						color: $black;
						text-decoration: underline;
						&:hover{
							text-decoration: none;
						}
					}
				}
				.submit-order {
					.customet-tax-section {
						text-align: left;
						margin-bottom: 14px;
						h2 {
							font-weight: 700;
							font-style: normal;
						}
					}
					.form-row {
						position: relative;
						@include respond(small) {
							margin: 0;
						}
						&.required {
							label {
								span {
									&:after {
										content: " *";
										color: $gray-dark;
									}
									&.required-indicator {
										display: none;
									}
								}
							}
						}
						.field-wrapper {
							width: 100%;
							@include respond(largeUp) {
								width: 74%;
							}
						}
						.form-caption {
							color: $gray-warm-old;
							font-size: 11px;
							@include respond(large) {
								margin-left: 26%;
							}
						}
						&.form-row-button {
							text-align: left;
							.button {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
		#secondary {
			@media screen and (min-width: 768px) {
				width: 35%;
			}
			@media screen and (min-width: 1200px) {
				width: 33%;
			}
			.section-header {
				@media screen and (min-width: 768px) {
					@include font-size(14px);
				}
				@media screen and (min-width: 1025px) {
					@include font-size(16px);
				}
				@media screen and (min-width: 1200px) {
					@include font-size(18px);
				}
				a {
					@media screen and (min-width: 768px) {
						@include font-size(10px);
						float: none;
					}
					@media screen and (min-width: 1025px) {
						@include font-size(12px);
					}
				}
			}
			.order-component-block .section-header a {
				float: right;
			}
		}
	}
}

