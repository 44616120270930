////////////////////////
// Checkout Billing Step
////////////////////////

#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-billing {
				.fieldset {
					.form-row.label-inline.form-indent {
						@include respond(small) {
							margin-left: 0;
						}
						label {
							padding-top: 0;
							height: auto;
						}
					}
					.payment-method {
						.form-row:not(.label-inline.form-indent) {
							label:not(.radio) {
								width: auto;
								line-height: 24px;
								margin-top: 4px;
								top: 0;
								position: relative;
								padding-top: 0;
								height: auto;
								@include respond(small) {
									width: 100%;
									line-height: 16px;
									margin-top: 4px;
									top: 0;
									position: relative;
									height: auto;
								}
							}
						}
						&.payment-method-expanded {
							.form-row.label-inline.form-indent {
								margin-left: 26.9%;
								@include respond(small) {
									margin-left: 0;
								}
							}
						}
					}

					&:not(.address) {
						.form-row {
							.field-wrapper {
								& + .form-field-tooltip {
									position: relative;
									top: 0;
									padding-top: 0;
									@media screen and (max-width:999px) {
										position: relative;
										top: 0;
										padding: 0;
										width: auto;
										float: left;
									}
								}
							}
						}
					}
					&.address {
						.form-row {
							@media screen and (max-width: 999px) {
								position: relative;
								display: inline-block;
								width: 100%;
							}
							.form-caption {
								float: left;
							}
							.field-wrapper {
								& + .form-field-tooltip {
									right: 5px;
									@include respond(largeUp) {
										left: 76%;
										top: 0;
									}
								}
							}
							label {
								width: auto;
								line-height: 16px;
								margin-top: 0px;
								top: 8px;
								position: relative;
								padding-top: 0;
								@include respond(small) {
									width: 100%;
									line-height: 26px;
									margin-top: 4px;
									top: 0;
									position: relative;
								}
							}
						}
					}
					.form-caption {
						margin: 0.4375rem 0 0;
						@include respond(small) {
							margin: 0;
						}
					}
					.form-row {
						&.cvn {
							.form-field-tooltip {
								left: auto;
								position: relative;
								top: auto;
								width: 22%;
								a {
									white-space: nowrap;
								}
							}
						}
						&.label-inline.form-indent {
							margin-left: 26%;
							@include respond(small) {
								margin: 0;
							}
						}
						.form-caption {
							margin: 0.4375rem 0 0 0;
							@include respond(small) {
								margin: 0;
							}
						}
						.input-select.country {
							& + .selected-country {
								min-height: 34px;
								line-height: 34px;
							}
						}
					}
					.payment-method[data-method="klarna_all"] {
						.klarna-label {
							cursor: pointer;
							padding: 0 0 10px 40px;
							position: relative;

							&::before {
								content: " ";
								border: 1px #999 solid;
								border-radius: 50%;
								height: 13px;
								left: 10px;
								position: absolute;
								top: 10px;
								width: 13px;
							}

							&::after {
								background-color: blue;
								border-radius: 50%;
								content: " ";
								height: 7px;
								left: 13px;
								position: absolute;
								top: 13px;
								opacity: 0;
								width: 7px;
							}

							&.active {
								&::before {
									border-color: blue;
								}

								&::after {
									opacity: 1;
								}
							}

							img {
								float: left;
								margin: 0 15px 0 0;
							}

							h5,
							p {
								font-family: $sans-serif;
								font-size: 14px;
								margin: 0;
							}

							h5 {
								font-weight: 500;
							}
						}

						.klarna-payments_more {
							font-size: 12px;
							padding-left: 30px;
							margin-bottom: 20px;

							&::before {
								border: 2px #000 solid;
								border-radius: 50%;
								content: "i";
								display: inline-block;
								font-size: 13px;
								line-height: 15px;
								margin-right: 5px;
								height: 18px;
								text-align: center;
								width: 18px;
							}

							a {
								color: #000;
								text-decoration: underline;

								&:hover {
									text-decoration: none;
								}
							}
						}

						.klarna-payments_button {
							.klarna-checkout__button--pay {
								&[disabled],
								&.disabled {
									background-color: $black;
									border-color: $black;
									color: $white;

									&::after {
										border-left: 3em solid #4c4c4c;
									}

									&::before {
										background-color: #4c4c4c;
									}
								}
							}

							.adyen-checkout__button__icon {
								margin: 0 0 0 12px;
							}
						}
					}
				}
				.form-row {
					position: relative;
                    @include respond(small) {
                        margin: 0;
                    }
                    &.required {
                        label {
                            span {
                                &:after {
                                    content: " *";
                                    color: $gray-dark;
                                }
                                &.required-indicator {
                                    display: none;
                                }
                            }
                        }
						label[for="dwfrm_billing_paymentMethods_creditCard_owner"],
						label[for="dwfrm_singleshipping_shippingAddress_email_emailAddress"] {
							span {
								&:after {
									white-space: nowrap;
								}
							}
						}
                    }
                    .field-wrapper {
						width: 100%;
                        @include respond(largeUp) {
                            width: 74%;
                        }
                    }
                    .form-caption {
                        color: $gray-warm-old;
                        font-size: 11px;
                        @include respond(large) {
                            margin-left: 26%;
                        }
                    }
                    &.month {
                        @include respond(small) {
                            margin-right: 4%;
                        }
                    }
                    &.month,
                    &.year {
                        .field-wrapper {
                            @include respond(large) {
                                width: 100%;
                            }
                        }
                    }
                    &.cvn {
                        .form-field-tooltip {
                            @include respond(large) {
                                left: 16rem;
                            }
                        }
                    }
				}
				.form-field-tooltip {
					left: 356px;
					position: absolute;
					top: 2px;
					padding-top: 0;
                    @include respond(small) {
                        width: auto;
                        left: auto;
                        right: 0;
                        top: 8px;
                    }
				}
				.fieldset {
					> .payment-method-options.form-indent {
						.payment-methods-tab-item {
							display: flex;
							flex-direction: column;
							@include respond(300px, 1181px) {
								align-items: center;
								text-align: center;
							}
						}
						.payment-methods-tab-item-wrapper {
							display: flex;
							align-items: flex-start;
							margin-bottom: 6px;
							@include respond(300px, 1181px) {
								flex-direction: column;
								align-items: center;
								text-align: center;
								margin-bottom: 4px;
							}
						}
						.form-row {
                            @include respond(large) {
                                margin-left: 0;
                                padding: 0.25rem 0.5rem 0.6rem;
                            }
							@include respond(300px, 1181px) {
								padding: 0.5rem 0.1rem 0.5rem;
							}
							&.Adyen {
								display: none;
							}
							.input-radio,
							#is-CREDIT_CARD {
								margin: 7px 8px 0 0;
								@include respond(300px, 1181px) {
									margin: 0;
								}
							}
							.icon-CREDIT_CARD {
								padding-top: 9px;
							}
							label {
								padding-left: 0;
								margin-right: 0;
								width: auto;
								max-width: 76px;
								min-height: 34px;
								height: auto;
								@include font-size(11px);
								@include respond(300px, 1181px) {
									@include font-size(9px);
									padding-top: 10px;
									text-align: center;
									min-height: 28px;
								}
							}
							span.icon {
								width: auto;
								background: none;
								margin: auto 0 0 0;
								height: 32px;
								display: flex;
								align-items: flex-end;
								@include respond(large) {
									height: 32px;
									margin: auto 0 0 0;
								}
								@include respond(small) {
									height: 28px;
									margin: auto 0 0 0;
								}
								@include respond(iphone) {
									height: 28px;
									margin: auto 0 0 0;
								}
								img {
									max-height: 100%;
									display: inline-block;
									height: auto;
									max-width: 100%;
								}
							}
						}
						#type {
							@include respond(largeUp) {
								margin-left: 24%;
							}
                            @include respond(large) {
                                margin-left: 0;
                            }
						}
						& > .form-row {
							label {
								font-size: 0.6875rem;
							}
						}
					}
					&.address {
						& > .form-row-button {
							display: none;
						}
					}
				}
			}
			.form-horizontal .field-wrapper {
				padding-left: 0;
			}
			.billing-coupon-code {
                .form-row.label-above {
                    @include respond(small) {
                        float: left;
                    }
                    .field-wrapper {
                        @include respond(large) {
                            width: 54%;
                        }
                    }
                }
                button#add-coupon {
                    @include respond(small) {
                        float: left;
                        margin-top: 30px;
						padding: .6em 20px;
                    }
                }
			}
		}
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset.address.open {
	& ~.fieldset.coupon-code,
	& ~ .payment-methods,
	& ~ .form-row.form-row-button {
		display: none !important;
	}
}

#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-billing {
				.payment-methods {
					.fieldset {
						& > .payment-method-options.emea-options {
							& > .payment-methods-tab-item {
								input {
									margin-top: 7px;
									@include respond(small) {
										margin-top: 0;
									}
								}
								label {
									font-size: 12px;
									margin-top: 5px;
									min-height: initial;
									@include respond(small) {
										margin-top: 0;
										font-size: 11px;
									}
								}
								&.scheme {
									label {
										max-width: initial;
									}
									.payment-methods-tab-item-icon {
										display: none;
									}
								}
								& ~ .payment-methods-tab-item {
									border-left: 0;

									&.klarna_account,
									&.klarna,
									&.klarna_paynow {
										display: none !important;
									}
								}
							}
						}
					}
					.payment-method[data-method="scheme"] {
						.adyen-checkout__card-input {
							display: inline-block;
							width: 100%;
							.adyen-checkout__card__form {
								display: flex;
								flex-direction: column;
							}
							.adyen-checkout__label {
								width: 100%;
								display: flex;
								flex-direction: column;
							}
							.adyen-checkout__label__text {
								color: #4C4C4C;
								display: inline-block;
								font-size: 14px;
								font-weight: bold;
								line-height: 1.7;
								text-align: left;
								width: 100%;
    							font-family: $sans-serif;
								text-overflow: initial;
								white-space: normal;
								&::after {
									content: ' *';
									display: inline;
								}
							}
							.adyen-checkout__input-wrapper {
								width: 100%;
								position: relative;
							}
							.adyen-checkout__error-text {
								border: none;
								color: #e32338 !important;
								font-family: $sans-serif;
								font-size: 12px;
								font-style: normal;
								font-weight: 400 !important;
								text-transform: none !important;
								width: 100%;
							}
							.adyen-checkout__card__brands {
								order: 1;
								gap: 12px;
								margin-top: 20px;
								.adyen-checkout__card__brands__brand-wrapper {
									height: 26px;
									width: 42px;
									img {
										height: 26px;
										width: 42px;
									}
								}
							}
							.adyen-checkout__field--cardNumber {
								order: 2;
							}
							.adyen-checkout__card__exp-cvc {
								order: 3;
								display: flex;
								flex-direction: column;
							}
							.adyen-checkout__input {
								background-color: #ffffff;
								border-radius: 2em;
								border: 1px solid #dfdfdf;
								padding-left: 16px;
								height: 32px;
								margin-top: 0;
								margin-bottom: 0;
								&.adyen-checkout__input--invalid,
								&.adyen-checkout__input--error {
									border-color: #e32338;
								}
							}
							.adyen-checkout__field {
								width: 100%;
								&.adyen-checkout__field--50 {
									width: 100%;
									& + .adyen-checkout__field--50 {
										margin-top: 16px;
									}
								}
							}
							.adyen-checkout__field__cvc {
								margin-left: 0;
							}
							.adyen-checkout__card__cvc__hint__wrapper {
								position: absolute;
								left: initial;
								right: 0;
								top: 0;
								width: 39px;
								display: flex;
								justify-content: flex-end;
								margin: 0;
								padding-right: 12px;
							}
							.adyen-checkout__card__holderName {
								input {
									&::-webkit-input-placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									&::-moz-placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									&::placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									&:-moz-placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									&:-ms-input-placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									&::-ms-input-placeholder {
										color: #b2b2b2;
										font-style: italic;
									}
									& ~ span.error {
										display: none !important;
									}
								}
							}
							.adyen-checkout-input__inline-validation {
								position: absolute;
								left: initial;
								right: 0;
								top: 17px;
								width: 28px;
								display: flex;
								justify-content: flex-end;
								margin: 0;
								padding-right: 12px;
							}
						}
					}

					.payment-method[data-method="klarna_account"],
					.payment-method[data-method="klarna_paynow"],
					.payment-method[data-method="klarna"] {
						display: none !important;
					}
				}
                .form-row.open {
                    .checkout-privacy-policy {
                        display: none;
                    }
                }
			}
            .checkout-priv-policy {
                display: block;
                margin-top: 20px;
            }
		}
	}
}

.asset__klarna {
	margin-bottom: 20px;
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row {
	flex: 1;
	padding: 8px;
	width: auto;
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-PayPal img, #wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-paypal img {
	@include respond(300px, 1181px) {
		height: auto;
		max-width: 55px;
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent .form-row {
	@include respond(800px, 1181px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-PayPal img, #wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-paypal img {
	width: 100%;
	height: auto;
	max-width: 50px;
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-PayPal, #wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent > .form-row span.icon-paypal {
	display: block;
	width: auto;
	height: auto;
	float: none;
	margin: 0;
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-methods .fieldset > .payment-method-options.emea-options > .payment-methods-tab-item label {
	margin: 0;
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent .payment-methods-tab-item-wrapper {
	align-items: center;
	margin: 0;
	min-height: 30px;
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-methods .fieldset > .payment-method-options.emea-options > .payment-methods-tab-item input {
	margin-top: 0;
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-method {
    padding: 20px 32px 0;
    @include respond(small) {
        padding: 12px 16px 0;
    }
}
