#wrapper.pt_order-confirmation #main {
    .confirmationregister .login-box-content .form-row {
        label {
            width: 16%;
			@media screen and (min-width: 768px) and (max-width: 939px) {
				width: 20%;
			}
        }
        .form-caption {
            margin-left: 0;
			@media screen and (min-width: 768px) and (max-width: 939px) {
				margin-left: 20%;
			}
        }
		.field-wrapper {
			@media screen and (min-width: 768px) and (max-width: 939px) {
				width: 80%;
			}
		}
    }
    .order-confirmation-details table.order-shipment-table tr td.line-item-price {
        white-space: nowrap;
    }
}
