﻿
.footer-bottom-bar .menu-global .menu-content {
    position: absolute;
    bottom: 30px;
    right: -58px;
    margin: 0;
    display: none;
    background: #fff;
    width: 100%;
    min-width: 280px;
    -webkit-transition: opacity ease-in-out 0.3s;
    -moz-transition: opacity ease-in-out 0.3s;
    -ms-transition: opacity ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-size: 0.75rem;
    border-radius: 2px;
    line-height: 1.2;
    padding: 0;
}
